import React, {Suspense, useEffect} from "react"
import {useRoutes} from "react-router-dom"
import Root from "./Root"
import {useDispatch, useSelector} from "react-redux"
import * as layoutConstants from "../constants/layout"
import PrivateRoute from "./PrivateRoute"
import callApi from "../utils/apiCaller"

import VerticalLayout from "../layouts/Vertical"

import DefaultLayout from "../layouts/Default"
import Logout from "../pages/account/Logout"
import NotScheduledOnDemandBookings2 from "../pages/bookings/onDemand/NotScheduledOnDemandBookings2"
import {setOnDemandNewBookingCount} from "../redux/actions"
import Locations from "../pages/services/Locations"
import LocationDetails from "../pages/services/LocationDetails"
import ServicePackageAddOnDetails from "../pages/servicePackages/ServicePackageAddOnDetails"
const BikeBrandDetails = React.lazy(() =>
  import("../pages/vehicles/Bikes/BikeBrandDetails")
)
const BikeBrandList = React.lazy(() =>
  import("../pages/vehicles/Bikes/BikeBrandList")
)

const ServiceLocations = React.lazy(() =>
  import("../pages/mapView/ServiceLocations")
)

const Login = React.lazy(() => import("../pages/account/Login"))
const JobsDailyCleaning = React.lazy(() =>
  import("../pages/jobs/JobsDailyCleaning")
)
const JobsOnDemand = React.lazy(() => import("../pages/jobs/JobsOnDemand"))
const JobsNotAttempted = React.lazy(() =>
  import("../pages/jobs/JobsNotAttempted")
)

const JobDetails = React.lazy(() =>
  import("../pages/jobs/jobDetails/JobDetails")
)
const ComplaintJobDetails = React.lazy(() =>
  import("../pages/jobs/jobDetails/ComplaintJobDetails")
)
const JobDetailsOnDemand = React.lazy(() =>
  import("../pages/jobs/jobDetails/JobDetailsOnDemand")
)
const CarBrandDetails = React.lazy(() =>
  import("../pages/vehicles/Cars/CarBrandDetails")
)
const JobsReported = React.lazy(() =>
  import("../pages/jobs/jobDetails/JobsReported")
)
const ServicePlaces = React.lazy(() =>
  import("../pages/services/ServicePlaces")
)
const CarBrandList = React.lazy(() =>
  import("../pages/vehicles/Cars/CarBrandList")
)
const ApartmentsDetails = React.lazy(() =>
  import("../pages/services/ApartmentsDetails")
)
const StaffDetails = React.lazy(() =>
  import("../pages/partners/partnerProfile/StaffDetails")
)

const ComplaintJobs = React.lazy(() =>
  import("../pages/jobs/jobDetails/ComplaintJobs")
)
const PartnersList = React.lazy(() => import("../pages/partners/PartnersList"))
const ReviewApplications = React.lazy(() =>
  import("../pages/partners/inReview/ReviewApplications")
)
const NewApplications = React.lazy(() =>
  import("../pages/partners/newApplications/NewApplications")
)
const InActiveApplications = React.lazy(() =>
  import("../pages/partners/inActiveApplications/InActiveApplications")
)
const PartnerProfile = React.lazy(() =>
  import("../pages/partners/partnerProfile")
)
const CreatePartner = React.lazy(() =>
  import("../pages/partners/createPartner/CreatePartner")
)

const PartnerLeaderBoard = React.lazy(() =>
  import("../pages/partners/leaderBoard/PartnerLeaderBoard")
)
const Interviews = React.lazy(() =>
  import("../pages/partners/interviews/Interviews")
)

const AssignedBookings = React.lazy(() =>
  import("../pages/bookings/AssignedBookings/AssignedBookings")
)
const BulkBookings = React.lazy(() =>
  import("../pages/bookings/BulkBookings/BulkBookings")
)
const NewBookings = React.lazy(() =>
  import("../pages/bookings/NewBookings/NewBookings")
)
const InactiveBookings = React.lazy(() =>
  import("../pages/bookings/InactiveBookings/InactiveBookings")
)
const CancelledBookings = React.lazy(() =>
  import("../pages/bookings/CancelledBookings/CancelledBookings")
)
const UnAssignedOnDemandBookings = React.lazy(() =>
  import("../pages/bookings/onDemand/UnAssignedOnDemandBookings")
)
const AssignedOnDemandBookings = React.lazy(() =>
  import("../pages/bookings/onDemand/AssignedOnDemandBookings")
)

const CanceledOnDemandBookings = React.lazy(() =>
  import("../pages/bookings/onDemand/CanceledOnDemandBookings")
)

const Users = React.lazy(() => import("../pages/users/Users"))

const BookingDetails = React.lazy(() =>
  import("../pages/bookings/BookingDetails")
)
const UserDetails = React.lazy(() => import("../pages/users/UserDetails"))

const OpenTickets = React.lazy(() => import("../pages/support/OpenTickets"))
const HoldTickets = React.lazy(() => import("../pages/support/HoldTickets"))
const ClosedTickets = React.lazy(() => import("../pages/support/ClosedTickets"))
const TicketDetails = React.lazy(() => import("../pages/support/TicketDetails"))
const CreateNewPartner = React.lazy(() =>
  import("../pages/partners/createNewPartner/CreateNewPartner")
)
const PartnerAttendance = React.lazy(() =>
  import("../pages/partners/attendance/PartnerAttendance")
)
const EditPartner = React.lazy(() =>
  import("../pages/partners/editPartner/EditPartner")
)

const ServicePackages = React.lazy(() =>
  import("../pages/servicePackages/ServicePackages")
)
const ServicePackagesDailyCleaning = React.lazy(() =>
  import("../pages/servicePackages/ServicePackagesDailyCleaning")
)

const ServicePackageDetails = React.lazy(() =>
  import("../pages/servicePackages/ServicePackageDetails")
)
const ServicePackageOnDemandDetails = React.lazy(() =>
  import("../pages/servicePackages/ServicePackageOnDemandDetails")
)
const EditServicePackageDetails = React.lazy(() =>
  import(
    "../pages/servicePackages/editServicePackageDetails/EditServicePackageDetails"
  )
)
const EditServicePackageOndemand = React.lazy(() =>
  import(
    "../pages/servicePackages/editServicePackageDetails/EditServicePackageOndemand"
  )
)
const EditAddons = React.lazy(() =>
  import("../pages/servicePackages/editAddons/EditAddons")
)

const CreateServicePackage = React.lazy(() =>
  import("../pages/servicePackages/createServicePackage/CreateServicePackage")
)
const CreateAddons = React.lazy(() =>
  import("../pages/servicePackages/createAddons/CreateAddons")
)

const ServicePackagesOndemand = React.lazy(() =>
  import("../pages/servicePackages/ServicePackagesOndemand")
)
const AutoDebit = React.lazy(() => import("../pages/payments/AutoDebit"))
const Referrals = React.lazy(() => import("../pages/referrals/Referrals"))
const Ratings = React.lazy(() => import("../pages/ratings/Rating"))

const loading = () => <div className=""></div>

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>,
}

const LoadComponent = ({component: Component}: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
)

const AllRoutes = () => {
  let Layout = VerticalLayout
  const dispatch = useDispatch()

  const getOnDemandBookings = () => {
    callApi("ondemand/unassignedscheduled", "POST").then((res) => {
      if (res.data) {
        dispatch(setOnDemandNewBookingCount(res?.totalJobs || 0))
      }
    })
  }

  useEffect(() => {
    const runEvery = () => {
      setTimeout(() => {
        getOnDemandBookings()
        runEvery()
      }, 200000)
    }
    runEvery()
    getOnDemandBookings()
  }, [])

  return useRoutes([
    {path: "/", element: <Root />},
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "account",
          children: [
            {path: "login", element: <LoadComponent component={Login} />},
            {path: "logout", element: <LoadComponent component={Logout} />},
          ],
        },
      ],
    },
    {
      path: "/",
      // element: <Layout />,
      element: <PrivateRoute component={Layout} />,
      children: [
        {
          path: "jobs",
          children: [
            {
              path: "daily-cleaning",
              element: <LoadComponent component={JobsDailyCleaning} />,
            },
            {
              path: "on-demand",
              element: <LoadComponent component={JobsOnDemand} />,
            },
            {
              path: "reported-jobs",
              element: <LoadComponent component={JobsReported} />,
            },
            {
              path: "not-attempted",
              element: <LoadComponent component={JobsNotAttempted} />,
            },
            {
              path: "complaint-jobs",
              element: <LoadComponent component={ComplaintJobs} />,
            },
            {
              path: "complaint-jobs-details",
              element: <LoadComponent component={ComplaintJobDetails} />,
            },
            {
              path: "job-details",
              element: <LoadComponent component={JobDetails} />,
            },
            {
              path: "job-details-on-demand",
              element: <LoadComponent component={JobDetailsOnDemand} />,
            },
          ],
        },
        {
          path: "partners",
          children: [
            {
              path: "leader-board",
              element: <LoadComponent component={PartnerLeaderBoard} />,
            },
            {
              path: "new-applications",
              element: <LoadComponent component={NewApplications} />,
            },
            {
              path: "review",
              element: <LoadComponent component={ReviewApplications} />,
            },
            {
              path: "list",
              element: <LoadComponent component={PartnersList} />,
            },
            {
              path: "add-partner",
              element: <LoadComponent component={CreatePartner} />,
            },
            {
              path: "edit-partner",
              element: <LoadComponent component={EditPartner} />,
            },
            {
              path: "inactive",
              element: <LoadComponent component={InActiveApplications} />,
            },
            {
              path: "partner-profile",
              element: <LoadComponent component={PartnerProfile} />,
            },
            {
              path: "interviews",
              element: <LoadComponent component={Interviews} />,
            },
            {
              path: "create-new-partner",
              element: <LoadComponent component={CreateNewPartner} />,
            },
            {
              path: "attendance",
              element: <LoadComponent component={PartnerAttendance} />,
            },
            {
              path: "staff-details",
              element: <LoadComponent component={StaffDetails} />,
            },
          ],
        },
        {
          path: "bookings",
          children: [
            {
              path: "new-bookings",
              element: <LoadComponent component={NewBookings} />,
            },
            {
              path: "assigned-bookings",
              element: <LoadComponent component={AssignedBookings} />,
            },
            {
              path: "bulk-bookings",
              element: <LoadComponent component={BulkBookings} />,
            },
            {
              path: "inactive-bookings",
              element: <LoadComponent component={InactiveBookings} />,
            },
            {
              path: "cancelled-bookings",
              element: <LoadComponent component={CancelledBookings} />,
            },
            {
              path: "booking-details",
              element: <LoadComponent component={BookingDetails} />,
            },
            {
              path: "addons-details",
              element: <LoadComponent component={BookingDetails} />,
            },
          ],
        },
        {
          path: "on-demand",
          children: [
            {
              path: "un-assigned",
              element: <LoadComponent component={UnAssignedOnDemandBookings} />,
            },
            {
              path: "not-scheduled",
              element: (
                <LoadComponent component={NotScheduledOnDemandBookings2} />
              ),
            },
            {
              path: "assigned",
              element: <LoadComponent component={AssignedOnDemandBookings} />,
            },
            {
              path: "canceled",
              element: <LoadComponent component={CanceledOnDemandBookings} />,
            },
          ],
        },
        {
          path: "users",
          children: [
            {
              path: "all-users",
              element: <LoadComponent component={Users} />,
            },
            {
              path: "user-details",
              element: <LoadComponent component={UserDetails} />,
            },
          ],
        },
        {
          path: "support",
          children: [
            {
              path: "open-tickets",
              element: <LoadComponent component={OpenTickets} />,
            },
            {
              path: "hold-tickets",
              element: <LoadComponent component={HoldTickets} />,
            },
            {
              path: "closed-tickets",
              element: <LoadComponent component={ClosedTickets} />,
            },
            {
              path: "ticket-details",
              element: <LoadComponent component={TicketDetails} />,
            },
          ],
        },
        {
          path: "service-places",
          children: [
            {
              path: "apartments",
              element: <LoadComponent component={ServicePlaces} />,
            },
            {
              path: "locations",
              element: <LoadComponent component={Locations} />,
            },
            {
              path: "apartment-details",
              element: <LoadComponent component={ApartmentsDetails} />,
            },
            {
              path: "location-details",
              element: <LoadComponent component={LocationDetails} />,
            },
          ],
        },
        {
          path: "service-packages",
          children: [
            {
              path: "all-packages",
              element: (
                <LoadComponent component={ServicePackagesDailyCleaning} />
              ),
            },
            {
              path: "on-demand",
              element: <LoadComponent component={ServicePackagesOndemand} />,
            },
            {
              path: "package-details",
              element: <LoadComponent component={ServicePackageDetails} />,
            },
            {
              path: "package-details-on-demand",
              element: (
                <LoadComponent component={ServicePackageOnDemandDetails} />
              ),
            },
            {
              path: "package-details-add-on",
              element: <LoadComponent component={ServicePackageAddOnDetails} />,
            },
            {
              path: "edit-service-package",
              element: <LoadComponent component={EditServicePackageDetails} />,
            },
            {
              path: "edit-service-package-ondemand",
              element: <LoadComponent component={EditServicePackageOndemand} />,
            },
            {
              path: "create-service-package",
              element: <LoadComponent component={CreateServicePackage} />,
            },
            {
              path: "create-addons",
              element: <LoadComponent component={CreateAddons} />,
            },
            {
              path: "edit-addon",
              element: <LoadComponent component={EditAddons} />,
            },
          ],
        },
        {
          path: "vehicles",
          children: [
            {
              path: "cars",
              element: <LoadComponent component={CarBrandList} />,
            },
            {
              path: "cars-brands-details",
              element: <LoadComponent component={CarBrandDetails} />,
            },
            {
              path: "bikes",
              element: <LoadComponent component={BikeBrandList} />,
            },
            {
              path: "bikes-brands-details",
              element: <LoadComponent component={BikeBrandDetails} />,
            },
          ],
        },
        {
          path: "payments",
          children: [
            {
              path: "auto-debit",
              element: <LoadComponent component={AutoDebit} />,
            },
          ],
        },
        {
          path: "referrals",
          element: <LoadComponent component={Referrals} />,
        },
        {
          path: "service-locations",
          element: <LoadComponent component={ServiceLocations} />,
        },
        {
          path: "ratings",
          element: <LoadComponent component={Ratings} />,
        },
      ],
    },
  ])
}

export {AllRoutes}
