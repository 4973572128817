import React, {useState} from "react"
import _ from "lodash"
import moment from "moment"
import {Col, Card, Table, Offcanvas} from "react-bootstrap"
import classNames from "classnames"
import {Link} from "react-router-dom"
import AssignCleanerComponent from "../AssignCleanerComponent"
import OnDemandSchedule from "../../../../components/OnDemandSchedule"

const AddonsCardForBooking = (props) => {
  const data = props.data || {}
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [show, setShow] = useState(false)

  const toggle = () => {
    setShow((prevState) => !prevState)
  }

  return (
    <Card>
      <Card.Body>
        <Col>
          {!_.isEmpty(data?.addons) && data?.isSubscriptionModel && (
            <>
              <h5 className="">Addons</h5>
              <Table className="table-center mb-0 mt-0" size="sm" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Status</th>
                    <th>Vehicle number</th>
                    <th>Partner</th>
                    <th>Expire date</th>
                    <th>Scheduled date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.addons?.map((project, index) => {
                    return (
                      <tr
                        key={index}
                        // style={{
                        //   cursor: "pointer",
                        // }}
                        // onClick={() => {
                        //   window.open(
                        //     `/bookings/booking-details?id=${project._id}`,
                        //     "_blank"
                        //   )
                        //   // navigate({
                        //   //   pathname: "/bookings/booking-details",
                        //   //   search: `?id=${project._id}`,
                        //   //   target: "_blanks",
                        //   // })
                        // }}
                      >
                        <>
                          <td>{index + 1}</td>
                          <td>
                            <span
                              className={classNames("badge badge-outline-dark")}
                            >
                              {project?.status}
                            </span>
                          </td>
                          <td>{project?.vehicleId?.license_number}</td>
                          <td>{project?.assigned_cleaner_id?.name}</td>
                          <td>
                            {moment(project?.expiry_date).format(
                              "DD MMM, YYYY"
                            )}
                            {/* {project?.addonsdaysLeft >= 0 && (
                              <strong
                                style={{fontSize: 10}}
                                className="badge badge-outline-primary ms-1"
                              >
                                {project?.addonsdaysLeft} days left
                              </strong>
                            )} */}
                          </td>
                          <td>
                            <div
                              style={{alignItems: "center", display: "flex"}}
                            >
                              {project?.isScheduled ? (
                                moment(project?.service_start_date).format(
                                  "DD MMM, YYYY"
                                )
                              ) : (
                                <span>Not Scheduled</span>
                              )}
                              {!project?.isCancelled && (
                                <button
                                  className="btn"
                                  style={{
                                    backgroundColor: "green",
                                    color: "white",
                                    borderRadius: 20,
                                    height: 28,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    marginLeft: 6,
                                  }}
                                  onClick={() => {
                                    setShowScheduleModal(true)
                                  }}
                                >
                                  {project?.isScheduled
                                    ? "Reschedule"
                                    : "Schedule"}
                                </button>
                              )}
                            </div>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/bookings/booking-details",
                                search: `?id=${project._id}`,
                              }}
                              className="action-icon"
                              target={"_blank"}
                            >
                              <i className="mdi mdi-eye"></i>
                            </Link>
                          </td>

                          {showScheduleModal && (
                            <OnDemandSchedule
                              data={project}
                              onHide={() => setShowScheduleModal(false)}
                              getData={() => {
                                setShowScheduleModal(false)
                                props.getData(data._id)
                              }}
                            />
                          )}
                        </>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </>
          )}
        </Col>
        {showAssignModal && (
          <AssignCleanerComponent
            name="Assign Partner"
            data={data}
            onHide={() => {
              setShowAssignModal(false)
            }}
            getData={props.getData}
            reload={() => {
              setShowAssignModal(false)
            }}
          />
        )}
        <Offcanvas
          show={show}
          onHide={toggle}
          placement="bottom"
          className="h-auto"
          style={{
            marginTop: 30,
            borderTopLeftRadius: 14,
            borderTopRightRadius: 14,
          }}
        >
          <Offcanvas.Header
            closeButton
            style={{marginLeft: "16rem", paddingBottom: 0}}
          >
            <Offcanvas.Title>Previous complaint details</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{paddingTop: 0}}>
            <div style={{marginLeft: "16rem", paddingBottom: 16}}></div>
          </Offcanvas.Body>
        </Offcanvas>
      </Card.Body>
    </Card>
  )
}

export default AddonsCardForBooking
