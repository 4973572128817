import React from "react"
import {Row, Col, Card} from "react-bootstrap"
import {Link} from "react-router-dom"
import callApi from "../../../utils/apiCaller"
import swal from "sweetalert"

const VehicleDetailsInBookings = (props) => {
  const data = props.data.vehicleId || {}

  const [isEditMode, setIsEditMode] = React.useState(false)
  const [vehicleNumber, setVehicleNumber] = React.useState(
    data?.license_number || ""
  )

  const handleOnChange = (e) => {
    if (data._id) {
      const info = {
        servicebookingId: props.data._id,
        license_number: vehicleNumber,
      }
      callApi("servicebooking/updatebooking", "POST", info).then((res) => {
        if (res.success) {
          // update swal success
          swal({
            title: "Success",
            text: "Vehicle updated successfully",
            icon: "success",
            button: "Ok",
          })
            .then(() => {
              setIsEditMode(false)
              props.getData(props.data._id)
            })
            .catch(swal.noop)
        } else {
          // update swal error
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Ok",
          })
            .then(() => {
              setIsEditMode(false)
              props.getData(props.data._id)
            })
            .catch(swal.noop)
        }
      })
    }
  }

  return (
    <Card>
      <Card.Body>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <h4 className="header-title mt-2">Vehicle details</h4>
          {!isEditMode && !props?.data?.isCancelled && (
            <div
              style={{cursor: "pointer", color: "#00a8ff"}}
              className="action-icon"
              onClick={() => setIsEditMode(true)}
            >
              <i className="mdi mdi-square-edit-outline action-icon mb-3 btn btn-success btn-block">
                Edit
              </i>
            </div>
          )}
        </div>
        <div className="table-responsive">
          <table className="table mb-0">
            <thead className="table-light">
              <tr>
                <th>Title</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{data?.name}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{data?.model_id?.car_body_type || "Bike"}</td>
              </tr>
              <tr>
                <td>Brand</td>
                <td>{data?.model_id?.carbrand || data?.model_id?.bikebrand}</td>
              </tr>
              <tr>
                <td>Model</td>
                <td>{data?.model_id?.name}</td>
              </tr>
              <tr>
                <td>Reg NO</td>
                <td>
                  {isEditMode ? (
                    <input
                      type="text"
                      className="form-control"
                      value={vehicleNumber}
                      onChange={(e) => {
                        // handleOnChange(e, "license_number")
                        setVehicleNumber(e.target.value)
                      }}
                    />
                  ) : (
                    data?.license_number
                  )}
                </td>
              </tr>
              <tr>
                <td>Fuel Type</td>
                <td>{data?.fuel_type}</td>
              </tr>
              <tr>
                <td>Tyre Type</td>
                <td>{data?.tyres_type}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {isEditMode && (
          <div className="text-end">
            <button
              className="btn btn-danger mt-2"
              style={{marginRight: "20px"}}
              onClick={() => setIsEditMode(false)}
            >
              <i className="mdi mdi-content-save"></i>Cancel
            </button>
            <button
              className="btn btn-success mt-2 pl-2 "
              onClick={() => {
                setIsEditMode(false)
                handleOnChange()
              }}
            >
              <i className="mdi mdi-content-save"></i> Save
            </button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default VehicleDetailsInBookings
