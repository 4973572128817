// @flow
import React, {useEffect, useState} from "react"
import {Row, Col} from "react-bootstrap"
import Select from "react-select"
import callApi from "../../../utils/apiCaller"
import {FormInput} from "../../../components"
import _ from "lodash"

const JobsFilterComponent = (props) => {
  const [cleanerDataFromApi, setCleanerDataFromApi] = React.useState([])
  const [selectedCleaner, setSelectedCleaner] = useState([])
  const [cities, setCity] = React.useState([])
  const [locationListByCity, setLocationListByCity] = React.useState([])
  const [location, setLocation] = React.useState([])
  const [selectedAgency, setSelectedAgency] = React.useState([])
  const [cityId, setCityId] = React.useState(null)
  const [apartments, setApartments] = React.useState([])
  const [apartmentsFromApi, setApartmentsFromApi] = React.useState([])
  const [vehicleType, setVehicleType] = useState("")
  const [timeSlotsFromApi, setTimeSlotsFromApi] = useState([])
  const [timeSlot, setTimeSlot] = useState([])
  const [blocksFromApi, setBlocksFromApi] = useState([])
  const [block, setBlock] = useState([])
  const [agencyDataFromApi, setAgencyDataFromApi] = useState([])

  const fetchAgencies = (num, search) => {
    const info = {
      pageNum: num,
    }
    if (search) {
      info.search = search
    }

    callApi("agency/getall", "POST", info).then((res) => {
      if (res.data) {
        setAgencyDataFromApi(res.data)
        const list = res?.data?.map((item) => {
          let role = item.role === "AGENCY_OWNER" ? "Agency" : "Independent"
          return {
            value: `${item.name} - ${role}`,
            label: `${item.name} - ${role}`,
            _id: item._id,
            ownerId: item.ownerId,
          }
        })
        setAgencyDataFromApi(list)
      }
    })
  }

  useEffect(() => {
    // getAllStaff()
    getCities()
    fetchAgencies(1)
    getAllApartments()
    getTimeSlots()
  }, [])

  const getCities = async () => {
    callApi(`city`, `get`).then((res) => {
      if (res.success) {
        setCity(res.data)
      }
    })
  }

  const getAllStaff = async (num, search) => {
    const info = {
      pageNum: num,
    }
    if (search) {
      info.search = search
    }

    callApi("partner/globalsearch", "POST", info).then((res) => {
      if (res.data) {
        const list = res?.data?.map((item) => {
          return {
            value: `${item.name} - ${item.phone}`,
            label: `${item.name} - ${item.phone}`,
            _id: item._id,
          }
        })
        setCleanerDataFromApi(list)
      }
    })
  }
  const getStaffByAgency = async (value) => {
    const data = {}
    if (value?._id) {
      data.agencyId = value.ownerId
    }
    callApi("partner/getStaff", "POST", data).then((res) => {
      if (res.success) {
        const list_b = res.data.map((partners) => {
          return {
            value: `${partners.name} - ${partners.phone}`,
            label: `${partners.name} - ${partners.phone}`,
            _id: partners._id,
          }
        })
        setCleanerDataFromApi(list_b)
      }
    })
  }
  const getApartments = async (value) => {
    const location = value.map((i) => {
      return i._id
    })

    callApi(`apartment`, `POST`, {locationId: location}).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setApartmentsFromApi(list_b)
      }
    })
  }
  const getAllApartments = async (value) => {
    const data = {}
    if (value) {
      data.search = value
    }
    callApi(`apartments/getall`, `POST`, data).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setApartmentsFromApi(list_b)
      }
    })
  }

  const getLocationListByCity = async (value) => {
    callApi(`location/${value}`, `get`).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setLocationListByCity(list_b)
      }
    })
  }

  const getBlocks = async (value) => {
    callApi(`blocks/getall`, `POST`, {
      apartmentId: value,
    }).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setBlocksFromApi(list_b)
      }
    })
  }

  const getTimeSlots = async () => {
    callApi(`dailytimeslot`, `get`).then((res) => {
      if (res.success) {
        setTimeSlotsFromApi(res.data)
      }
    })
  }

  const handlePartnerChange = (inputValue) => {
    fetchAgencies(1, inputValue)
  }

  const applyFilters = (e) => {
    let filterObject = {}
    if (cityId) {
      filterObject.cityId = [cityId]
    }
    if (!_.isEmpty(location)) {
      filterObject.locationId = location.map((i) => {
        return i._id
      })
    }
    if (!_.isEmpty(apartments)) {
      filterObject.servicePlaceNameId = apartments.map((i) => {
        return i._id
      })
    }
    if (!_.isEmpty(vehicleType)) {
      filterObject.vehicle_type = vehicleType
    }
    if (!_.isEmpty(timeSlot)) {
      filterObject.timeSlot = [timeSlot]
    }
    if (!_.isEmpty(block)) {
      filterObject.block_id = block.map((i) => {
        return i._id
      })
    }
    console.log("selectedCleaner", selectedCleaner)
    if (!_.isEmpty(selectedCleaner?._id)) {
      filterObject.cleanerId = selectedCleaner?._id
    }
    console.log("selectedAgency", selectedAgency)
    if (!_.isEmpty(selectedAgency)) {
      filterObject.agencyId = selectedAgency._id
    }
    props.applyAllFilters(filterObject)
  }

  const handleApartmentChange = (inputValue) => {
    getAllApartments(inputValue)
  }

  return (
    <>
      <>
        <Col md={6} className="mb-2 mt-2">
          {!props.showPartner && (
            <>
              <p className="fw-bold ">Search agency or independent partner</p>
              <Select
                isClearable
                options={agencyDataFromApi}
                className="react-select"
                classNamePrefix="react-select"
                value={selectedAgency}
                onChange={(e) => {
                  setSelectedAgency(e)
                  setSelectedCleaner([])
                  getStaffByAgency(e)
                }}
                onInputChange={(text) => fetchAgencies(1, text)}
                placeholder="Select or search Agency"
              ></Select>
            </>
          )}
        </Col>
        {!_.isEmpty(cleanerDataFromApi) && (
          <Col md={6} className="mb-2 mt-2">
            <>
              <p className="fw-bold ">Select or search partner</p>
              <Select
                isClearable
                options={cleanerDataFromApi}
                className="react-select"
                classNamePrefix="react-select"
                value={selectedCleaner}
                onChange={(e) => {
                  setSelectedCleaner(e)
                }}
                onInputChange={handlePartnerChange}
                placeholder="Select or search cleaner"
              ></Select>
            </>
          </Col>
        )}
        <Row>
          <Col md={4} className="mb-2 mt-1">
            <FormInput
              style={{
                marginTop: 2,
              }}
              labelClassName="fw-bold"
              label="City"
              type="select"
              name="city"
              placeholder="Enter password"
              key="city"
              defaultValue={"select city"}
              onChange={(e) => {
                getLocationListByCity(e.target.value)
                setCityId(e.target.value)
                setLocation([])
              }}
            >
              <option key="City" value="City">
                --select city--
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city._id} _id={city._id}>
                  {city.name}
                </option>
              ))}
            </FormInput>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Location</p>
            <Select
              isMulti={true}
              options={locationListByCity}
              className="react-select"
              classNamePrefix="react-select"
              value={location}
              onChange={(e) => {
                setLocation(e)
                setApartments([])
                getApartments(e)
              }}
              placeholder="Select Location"
            ></Select>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Apartments</p>
            <Select
              isMulti={true}
              options={apartmentsFromApi}
              className="react-select"
              classNamePrefix="react-select"
              value={apartments}
              onChange={(e) => {
                if (!_.isEmpty(e[0]?._id)) {
                  getBlocks(e[0]._id)
                }
                setApartments(e)
              }}
              placeholder="Select Apartments"
              onInputChange={handleApartmentChange}
            ></Select>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Block</p>
            <Select
              isMulti={true}
              options={blocksFromApi}
              className="react-select"
              classNamePrefix="react-select"
              value={block}
              onChange={(e) => {
                setBlock(e)
              }}
              placeholder="Select Blocks"
              onInputChange={handleApartmentChange}
            ></Select>
          </Col>
          <Col md={4} className="mb-2 mt-1">
            <FormInput
              style={{
                marginTop: 2,
              }}
              isClearable
              labelClassName="fw-bold"
              label="Vehicle type"
              type="select"
              name="vehicle_type"
              placeholder="Select vehicle"
              key="vehicle_type"
              value={vehicleType}
              defaultValue={"vehicle type"}
              onChange={(e) => {
                setVehicleType(e.target.value)
              }}
            >
              <option key="" value="">
                --Select vehicle type --
              </option>
              <option key="car" value="car">
                Car
              </option>
              <option key="bike" value="bike">
                Bike
              </option>
            </FormInput>
          </Col>
          <Col md={4} className="mb-2 mt-1">
            <FormInput
              style={{
                marginTop: 2,
              }}
              isClearable
              labelClassName="fw-bold"
              label="Time slots"
              type="select"
              name="timeSlot"
              placeholder="Select vehicle"
              key="timeSlot"
              onChange={(e) => setTimeSlot(e.target.value)}
            >
              <option key="" value="">
                --Select time slots --
              </option>
              {timeSlotsFromApi.map((item) => (
                <option key={item} value={item} _id={item}>
                  {item}
                </option>
              ))}
            </FormInput>
          </Col>
        </Row>
      </>
      <div className="text-end mt-2">
        <button
          className="btn btn-bloc "
          style={{backgroundColor: "#2A882A", color: "white"}}
          onClick={() => applyFilters()}
        >
          <i className="mdi dripicons-checkmark mr-1"></i> Apply selected
          filters
        </button>
      </div>
    </>
  )
}

export default JobsFilterComponent
